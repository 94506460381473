<template>
   <div>   
     <!--  广告位弹框 -->
     <el-dialog
      width="40%"
      :close-on-click-modal="false"
      :visible.sync="advertisingVisible"
     >
        <div slot="title">
          <span v-if="type3 == 1">添加定时任务(广告位)</span>
          <span v-if="type3 == 2">编辑定时任务(广告位)</span>
        </div>
        <el-form :label-position="labelPosition" :model="formModel" label-width="120px">
          <el-form-item label="任务名称">
            <el-input v-model="formModel.taskName" placeholder="请输入任务名称" style="width: 65%"></el-input>
          </el-form-item>
          <el-form-item label="为广告位创建">
            <el-select
              style="width: 65%"
              placeholder="请选择"
              :disabled="type3 == 2?true:false"
              v-model="formModel.createAdverte"
            >
              <el-option label="竞价调整" value="adjustment"></el-option>
            </el-select>
          </el-form-item>
          <div v-show="formModel.createAdverte?true:false">
            <!-- 应用至广告活动 -->
            <el-row type="flex" justify="start">
              <h4 style="margin-right: 6px">应用至广告活动</h4>
              <el-tooltip effect="dark" placement="top"> 
                <div slot="content">
                  <span>您可以将任务对象应用到这一级别的所有广告系列广告活动、广告组、广告、关键词、商品定位和搜索词。</span><br/>
                  <span>或者，您也可以在下面选中所需的复选框，仅将任务对象应用到选中的复选</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </el-row>
            <!-- 广告类型 -->
            <el-row type="flex" justify="start" style="margin: 26px 0 0 50px">
              <span style="margin-right: 20px">广告类型</span>
              <el-radio v-model="formModel.adverTypes" label="sp">SP广告</el-radio>
            </el-row>
            <!-- 应用范围 -->
            <el-row type="flex" justify="start" style="margin: 15px 0 0 50px">
              <span style="width: 75px; margin-top: 15px">应用范围</span>
              <el-radio-group v-model="formModel.adverApplicaRange">
                <el-radio label="enabled">所有已启动的广告活动</el-radio>
                <el-radio label="all">所有已启动和暂停广告活动</el-radio>
                <el-radio label="overbuget">所有超预算的广告活动</el-radio>
                <el-radio label="ids" style="margin-top: 15px">
                  <span @click="onClickAdCampaign" style="color: #1E90FF">选择广告活动</span>
                </el-radio>
              </el-radio-group>
            </el-row>
            <!-- table 表格 -->
            <el-row v-show="advertiseTableShow">
              <el-table
               border
               height="300"
               :data="advertiseTableData"
               style="margin-top: 25px; width: 100%"
               header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
              >
                <el-table-column label="广告活动" prop="name" align="center"></el-table-column>
                <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
                <el-table-column label="状态"  align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.state == 'paused'" style="color: #8B0000">暂停</span>
                    <span v-if="scope.row.state == 'enabled'" style="color: #2E8B57">启动</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-link :underline="false" type="primary" @click="handleRemove(scope.$index)">移除</el-link>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <!-- 操作类型 -->
            <el-row type="flex" justify="start" style="margin-top: 26px">
              <h4 style="width: 95px; margin-right: -20px; margin-top: 8px">操作类型</h4>
              <div>
                <el-row style="margin-top: 8px">
                  <span>说明: 设置两个广告位竞价时, 需要同时满足条件才会执行操作</span>
                </el-row>
                <el-row type="flex" justify="start" style="margin-top: 30px;">
                  <p class="title">搜索结果顶部(首页)</p>
                  <p style="margin: 8px 30px 0">调至</p>
                  <el-input style="width: 120px" placeholder="请输入" v-model="operationTop"></el-input>
                </el-row>
                <el-row type="flex" justify="start" style="margin-top: 30px;">
                  <p class="title">商品页面</p>
                  <p style="margin: 8px 30px 0">调至</p>
                  <el-input style="width: 120px" placeholder="请输入" v-model="operationProduct"></el-input>
                </el-row>
                <el-row style="margin-top: 20px">
                  <span>搜索结果的其余位置 -- </span>
                </el-row>
              </div>
            </el-row>
            <!--  执行条件 -->
            <el-row type="flex" justify="start" style="margin-top: 26px">
              <h4 style="margin-right: 8px; margin-top: 2px">执行条件</h4>
              <el-tooltip effect="dark" placement="top" content="满足执行条件的要求定时任务才会生效">
                <p class="el-icon-question" style="margin-right: 15px; margin-top: 2px"></p>
              </el-tooltip>
              <el-popover
               width="500" 
               placement="right"
               trigger="click"
               v-model="showVisible"
              >
               <el-row v-for="(item, index3) in collectionList" :key="index3" style="margin-bottom: 15px">
                 <el-select
                   placeholder="执行条件"
                   v-model="item.conditional"
                   style="width: 150px; margin-right: 10px"
                 >
                  <el-option
                    v-for="item in  performsConditional"
                    :key="item.id"
                    :label="item.values"
                    :value="item.id"
                    @click.native="conditionalSwitch(item, index3)"
                  ></el-option>
                 </el-select>
                 <!-- id为ctr和cvr和acos -->
                 <el-select 
                   v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false"
                   v-model="item.compare"
                   placeholder="请选择"
                   style="width: 150px; margin-right: 10px"
                 >
                   <el-option
                    v-for="item in JudgeConditions11"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                    @click.native="compareSwitch(item, index3)"
                   ></el-option>
                 </el-select>
                 <!-- id为非ctr和cvr和acos -->
                 <el-select 
                   v-show="item.conditional=='impressions'||
                   item.conditional=='clicks'||
                   item.conditional=='cost'||
                   item.conditional=='ordered'||
                   item.conditional=='sales'||
                   item.conditional==''?true:false"
                   v-model="item.compare"
                   placeholder="请选择"
                   style="width: 150px; margin-right: 10px"
                 >
                   <el-option
                    v-for="item in JudgeConditions22"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                    @click.native="compareSwitch(item, index3)"
                   ></el-option>
                 </el-select>
                 <el-input v-model="item.amount" placeholder="请输入" style="width: 150px; margin-right: 10px">
                    <i slot="suffix" v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false">
                      <img src="../../assets/common/percent.png" style="width: 26px; height: 26px; margin-top: 2px">
                    </i>
                    <i slot="suffix" v-show="item.conditional=='cost'||item.conditional=='sales'?true:fales">
                      <img src="../../assets/common/Jpy.png" style="width: 30px; height: 30px; margin-top: 1px; margin-left: 20px">
                    </i>
                 </el-input>
                 <p class="el-icon-close removeClass" @click="handleDelete(index3)"></p>
               </el-row>
               <el-row style="margin-top: 10px">
                 <el-button type="text" icon="el-icon-plus" :disabled="collectionList.length == 5? true:false" @click="handlePlus">添加</el-button>
               </el-row>
               <el-row type="flex" justify="end">
                 <el-button size="mini" @click="showVisible = false">取消</el-button>
                 <el-button size="mini" type="primary" @click="handleConfirm">确定</el-button>
               </el-row>
               <el-link :underline="false" type="primary" slot="reference">添加条件</el-link>
              </el-popover>
            </el-row>
            <el-row style="margin-top: 20px">
              <el-tag
               size="medium"
               closable
               :key="tag"
               v-for="(tag, index3) in dynamicTags3"
               @close="handleClose3(tag, index3)"
               style="margin-left: 15px"
              >{{tag}}</el-tag>
            </el-row>
            <el-row type="flex" justify="start" style="margin-top: 25px" v-show="adTimeRangeShow">
              <span style="margin: 5px 15px 0 0">执行条件的时间范围</span>
              <el-select
                size="mini"
                placeholder="请选择"
                style="width: 120px"
                v-model="conditionalTimeRangeValue" 
              >
               <el-option 
                v-for="item in appTimeRangeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
               ></el-option>
              </el-select>
            </el-row>
            <!-- 执行频率 -->
            <el-row type="flex" justify="start" style="margin-top: 35px">
              <h4 style="margin-right: 8px; margin-top: 8px">执行频率</h4>
              <el-tooltip effect="dark" placement="top" content="请指定执行相应操作的执行频率">
                <span class="el-icon-question" style="margin-right: 15px; margin-top: 8px"></span>
              </el-tooltip>
              <el-select
                size="small"
                style="width: 150px"
                v-model="frequencyType"
              >
                <el-option
                 v-for="item in ExecutionFrequency"
                 :key="item.id"
                 :label="item.values"
                 :value="item.id"
                ></el-option>
              </el-select>
              <!-- 一次 -->
              <el-date-picker
                v-show="frequencyType=='0'?true:false"
                v-model="adverDateTime"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm" 
                placeholder="选择日期时间"
                style="width: 200px; margin-left: 10px"
                >
              </el-date-picker>
              <!-- 每日 -->
              <el-time-picker
                v-show="frequencyType=='1'?true:false"
                v-model="adverTimePoint"
                placeholder="时间点"
                format="HH:mm"
                value-format="HH:mm"
                style="width: 160px; margin-left: 10px">
              </el-time-picker>
              <!-- 每周 -->
              <div v-show="frequencyType=='2'?true:false">
               <el-select 
                 v-model="executeDay"
                 placeholder="请选择天"
                 style="width: 110px; margin-left: 10px"
               >
                 <el-option
                   v-for="item in oneWeekList"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id"
                 ></el-option>
               </el-select>
               <el-time-picker
                 v-model="adverTimePoint"
                 type="datetime"
                 format="HH:mm"
                 value-format="HH:mm"
                 placeholder="时间点"
                 style="width: 160px; margin-left: 10px"
                 >
               </el-time-picker>
              </div>
              <!-- 每月 -->
              <div v-show="frequencyType=='3'?true:false">
               <el-select 
                 v-model="executeDay"
                 placeholder="选择天"
                 style="width: 120px; margin-left: 10px"
               >
                 <el-option
                  v-for="item in oneMonthDate"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                 ></el-option>
               </el-select>
               <el-time-picker
                v-model="adverTimePoint"
                type="datetime"
                format="HH:mm"
                value-format="HH:mm"
                placeholder="时间点"
                style="width: 180px; margin-left: 10px"
               >
              </el-time-picker>
            </div>
            </el-row>
            <!-- 执行通知 -->
            <el-row style="margin-top: 40px">
              <h4>执行通知</h4>
              <el-row v-show="adverNoticeTime=='0'?false:true" style="margin:18px 0 18px 50px; ">
                <span style="margin-right: 15px">通过邮件</span>
                <el-input v-model="adverEmail" style="width: 40%" placeholder="请输入邮箱账号"></el-input>
              </el-row>
              <el-row style="margin-left: 50px">
                <span style="margin-right: 15px">发送结果</span>
                <el-select
                  clearable
                  style="width: 40%"
                  v-model="adverNoticeTime"
                  placeholder="请选择通知时间"
                >
                  <el-option
                    v-for="item in sendResultsList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-row>
            </el-row>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="advertisingVisible = false">取 消</el-button>
          <el-button v-if="type3 == 1" type="primary" @click="handleAdConfirm(1)">确 定</el-button>
          <el-button v-if="type3 == 2" type="primary" @click="handleAdConfirm(2)">确 定</el-button>
        </span>
     </el-dialog>

     <!-- 选择执行对象(广告位) -->
     <el-dialog
      width="40%"
      title="选择执行对象"
      :visible.sync="advertiseObjectVisible"
     >
      <el-row type="flex" justify="start">
        <el-input 
         clearable
         @clear="advertiseSearch()"
         placeholder="搜索广告活动" 
         v-model="pageList.advertise" 
         style="margin-right: 18px; width: 200px"
        ></el-input>
        <el-button icon="el-icon-search" type="primary" @click="search">搜索</el-button>
      </el-row>
      <el-table
        border
        height="600px"
        ref="multipleTable3"
        row-key="campaignId"
        :data="advertiseTableList"
        style="margin-top: 35px; width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
        <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 'enabled'" style="color: #2E8B57">启用</span>
            <span v-if="scope.row.state == 'paused'" style="color: #8B0000">暂停</span>
          </template>
        </el-table-column>
        <el-table-column label="所属广告活动" prop="campaignName" align="center"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[20, 30, 50, 100]"
        :total="total"
        :page-size="pageList.pageSize"
        :current-page="pageList.current"
        style="margin-top: 25px; text-align: center"
      >
      </el-pagination>
      <span slot="footer">
        <el-button @click="advertiseObjectVisible = false">取 消</el-button>
        <el-button type="primary" @click="advertiseConfirm">确 定</el-button>
      </span>
     </el-dialog>
   </div>
</template>
<script>
import {
  listObjectByType,  // 查询应用对象
  addScheduled,     // 新增事件
  editScheduled,    // 编辑
 } from"@/api/Advertising/modifyPrice.js";
export default {
   props: {
     profileId: {
       type: String,
     },
     editId: {
       type: String,
     }
   },
   data() {
     return {
       total: 0,
       type3: 0,  // type3 为 1 是添加操作 type 为 2 是修改操作
       pageList: {
         advertise: "",
         pageSize: 20,
         current: 1,
       },
       advertiseTableShow: false,
       advertiseTableList: [],
       advertiseTableData: [],
       dateTimeShow: false,
       timePointShow: false,
       formModel: {
         taskName: "",  // 任务名称
         createAdverte: "",  // 创建广告位
         adverTypes: "sp",
         adverApplicaRange: "",
       },
       operationTop: "",
       operationProduct: "",
       advertisingVisible: false,
       advertiseObjectVisible: false,
       adverApplicaRange: "",
       adverDateTime: "",
       collectionList: [],
       frequencyType: "",
       ExecutionFrequency: [
         { id: "0", values: "一次" },
         { id: "1", values: "每日" },
         { id: "2", values: "每周" },
         { id: "3", values: "每月" }
       ],
       oneWeekList: [
         { id: "0", value: "周日"},
         { id: "1", value: "周一"},
         { id: "2", value: "周二"},
         { id: "3", value: "周三" },
         { id: "4", value: "周四" },
         { id: "5", value: "周五" },
         { id: "6", value: "周六" }
       ],
       oneMonthDate: [
         { id: "0", value: "1号" },
         { id: "1", value: "2号" },
         { id: "2", value: "3号" },
         { id: "3", value: "4号" },
         { id: "4", value: "5号" },
         { id: "5", value: "6号" },
         { id: "6", value: "7号" },
         { id: "7", value: "8号" },
         { id: "8", value: "9号" },
         { id: "9", value: "10号" },
         { id: "10", value: "11号" },
         { id: "11", value: "12号" },
         { id: "12", value: "13号" },
         { id: "13", value: "14号" },
         { id: "14", value: "15号" },
         { id: "15", value: "16号" },
         { id: "16", value: "17号" },
         { id: "17", value: "18号" },
         { id: "18", value: "19号" },
         { id: "19", value: "20号" },
         { id: "20", value: "21号" },
         { id: "21", value: "22号" },
         { id: "22", value: "23号" },
         { id: "23", value: "24号" },
         { id: "24", value: "25号" },
         { id: "25", value: "26号" },
         { id: "26", value: "27号" },
         { id: "27", value: "28号" },
         { id: "28", value: "29号" },
         { id: "29", value: "30号" },
         { id: "30", value: "31号" },
       ],
       sendResultsList: [
         { id: "0", value: "不通知" },
         { id: "1", value: "每次运行任务时通知" },
         { id: "2", value: "仅在有更改和错误时发送" },
         { id: "3", value: "仅有错误时通知" },
       ],
       performsConditional: [   // 执行条件 
         { id: "impressions", values: "展示量" },
         { id: "clicks", values: "点击量" },
         { id: "cost", values: "花费" },
         { id: "ordered", values: "广告订单量" },
         { id: "sales", values: "销售额" },
         { id: "cvr", values: "CVR" },
         { id: "acos", values: "ACOS" },
         { id: "ctr", values: "点击率" }
       ], 
       JudgeConditions11: [
         { id: "2", value: "小于", name: "<" },
         { id: "1", value: "大于", name: ">" }
       ],
       JudgeConditions22: [
         { id: "2", value: "小于", name: "<" },
         { id: "4", value: "小于或等于", name: "≤" },
         { id: "0", value: "等于", name: "=" },
         { id: "1", value: "大于", name: ">" },
         { id: "3", value: "大于或等于", name: "≥" }
       ],
       multipleSelection: [],
       showVisible: false,
       dynamicTags3: [],
       adverEmail: "",
       adverNoticeTime: "",
       conditionalTimeRangeValue: "",
       adTimeRangeShow: false,
       appTimeRangeList: [
         {id: 0, name: "当天"},
         {id: 1, name: "昨天"},
         {id: 2, name: "当周"},
         {id: 3, name: "上周"},
         {id: 4, name: "当月"}, 
         {id: 5, name: "上月"}, 
         {id: 6, name: "最近7天"}, 
         {id: 7, name: "最近14天"}, 
         {id: 8, name: "最近30天"}, 
         {id: 9, name: "所有时间"}, 
       ],
       executeDay: "",
       adverTimePoint: "",
     }
   },
   computed: {}, 
   watch: {},
   created() {
   },
   mounted() {

   },
   methods: {
     // 广告位清空数据
     adverInit() {
       this.formModel.taskName = "";
       this.formModel.createAdverte = "";
       this.formModel.adverApplicaRange = "";
       this.operationTop = "";
       this.operationProduct = "";
       this.dynamicTags3 = [];
       this.conditionalTimeRangeValue = "";
       this.frequencyType = "";
       this.adverDateTime = "";
       this.adverTimePoint = "";
       this.executeDay = "";
       this.adverEmail = "";
       this.adverNoticeTime = "";
     },
     onOpen() {
      this.advertisingVisible = true;
     },
     handleAdConfirm(type3) {
      if(!this.formModel.taskName) {
        this.$message.error('定时任务名称不能为空');
        return false;
      }
      if(!this.formModel.createAdverte) {
        this.$message.error('定时任务类型不能为空');
        return false; 
      }
      if(!this.formModel.adverApplicaRange) {
        this.$message.error('应用范围不能为空');
        return false; 
      }
      if(!this.adverNoticeTime) {
        this.$message.error('请选择通知类型');
        return false;
      }
      var ids  = [];
      this.multipleSelection.forEach(data=> {
        ids.push(data.campaignId);
      })
      var conditionItemList = [];
      this.collectionList.forEach(data=> {
        let object = {
          conditional: data.conditional,   // 执行条件
          compare: data.compare,   // 判断大小
          value: data.amount,    // 输入金额
          conditionalRange: this.conditionalTimeRangeValue,    // 应用时间
        }
        conditionItemList.push(object);
      })
      let data= {
        name: this.formModel.taskName,
        objectType: "pages",
        type: this.formModel.createAdverte,  // 为广告位创建
        adType: this.formModel.adverTypes,   // 广告类型sp
        objectRange: this.formModel.adverApplicaRange == 'ids'?ids.join():this.formModel.adverApplicaRange,  // 应用对象范围
        conditionItemList: conditionItemList,  // 执行条件
        operationTop: this.operationTop,
        operationProduct: this.operationProduct,
        executeType: this.frequencyType, 
        executeDay: this.executeDay,    // 执行周期 每周(0-6)
        executeDate: this.adverDateTime.slice(0, 10),
        executeTime: this.frequencyType=='0'?this.adverDateTime.slice(11, 16):this.adverTimePoint,
        notificationEmail: this.adverEmail,  // 	通知email
        notificationType: this.adverNoticeTime,  // 执行类型
        profileId: this.profileId,
        id: type3 == 2? this.editId: ""
      }
      if(type3 == 1) {
        addScheduled(data)
        .then(res=> {
          console.log(res, '123456');
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.advertisingVisible = false;
            this.$message.success(res.data.message);
            this.$parent.asyncSearch();  // 刷新列表
            this.$nextTick(()=> {
              this.$refs.multipleTable3.clearSelection();
            })
          }
        })
      }else if(type3 == 2) {
        editScheduled(data)
        .then(res=> {
          console.log(res, '123456');
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.advertisingVisible = false;
            this.$message.success(res.data.message);
            this.$parent.asyncSearch();  // 刷新列表
          }
        })
      }

       this.advertisingVisible = true;
     },
     onClickAdCampaign() {
       this.search();
       this.advertiseObjectVisible = true;
     },
     handleSelectionChange(val) {
       this.multipleSelection = val;
     },
     // 广告位弹框确定
     advertiseConfirm() {
      this.advertiseTableData = this.multipleSelection;
      this.advertiseObjectVisible = false;
      this.advertiseTableShow = true;
     },
     // 执行条件切换 
     conditionalSwitch(item, index3) {
       this.collectionList[index3].conditional = item.id;
       this.collectionList[index3].conditionsvalues = item.values;
     }, 
     // 判断条件切换 
     compareSwitch(item, index3) {
       this.collectionList[index3].compare = item.id;
       this.collectionList[index3].judgename = item.name;
       this.collectionList[index3].judgevalues = item.value;
     },
     handleConfirm() {
       for (let h = 0; h<this.collectionList.length; h++) {
         if(!this.collectionList[h].amount) {
           this.$message.error("执行条件数值不可为空");
           return false;
         }
       }
       this.dynamicTags3 = this.collectionList.map(data=> {
         return data.conditionsvalues + data.judgename + data.amount;
       }) 
       this.showVisible = false;  
       this.adTimeRangeShow = true; 
     },
     // 添加
     handlePlus() {
       this.collectionList.push({
         JPYShow: false,
         percentShow: false,
         conditional: "",
         compare: "",
         amount: "",
         conditionsvalues: "",  // 执行数值
         judgevalues: "",
         judgename: "",
       })
     },
     // 删除(广告位)
     handleDelete(index3) {
       this.collectionList.splice(index3, 1);
     },
     // 关闭按钮(广告位) 
     handleClose3(tag, index3) {
       this.dynamicTags3.splice(this.dynamicTags3.indexOf(tag), 1);
       this.collectionList.forEach((item, _self3)=> {
         if(index3 == _self3) {
            this.collectionList.splice(_self3, 1);
         }
       })
     },
     search() {
       this.pageList.current = 1;
       this.pageList.pageSize = 20;
       this.advertiseSearch();
     },
     // 广告搜索
     advertiseSearch() {
       let params = {
         objectType: "pages",
         content: this.pageList.advertise,
         profileId: this.profileId,
         pageSize: this.pageList.pageSize,
         current: this.pageList.current,
       }  
       listObjectByType(params)
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           var ids  = [];
           this.multipleSelection.forEach(data=> {
             ids.push(data.campaignId);
           })
           this.advertiseTableList = res.data.values;
           this.total = res.data.pageInfo.total;
           this.$nextTick(()=> {
             this.advertiseTableList.forEach(row=> {
               if(ids.indexOf(row.campaignId)>=0) {
                 this.$refs.multipleTable3.toggleRowSelection(row, true);
               }
             })
           })
         }
       })
     },
     // 移除一项
     handleRemove(index) {
       this.advertiseTableData.splice(index, 1)
     },
     handleSizeChange(newSize) {
       this.pageList.pageSize = newSize;
       this.advertiseSearch();
     },
     handleCurrentChange(newCurrent) {
       this.pageList.current = newCurrent;
       this.advertiseSearch();
     },
   },
}
</script>


<style lang="scss" scoped>
.title {
  margin-top: 8px !important;
}
</style>